import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TezosToolkit } from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';

const App = () => {
  const [wallet, setWallet] = useState(null);
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [payload, setPayload] = useState('');
  const [error, setError] = useState('');

  const Tezos = new TezosToolkit('https://mainnet.smartpy.io');
  const beaconWallet = new BeaconWallet({
    name: 'Password Claim',
    appUrl: 'http://password-claim.objkt.party/',
    network: {type: 'mainnet'},
  });

  Tezos.setWalletProvider(beaconWallet);

  useEffect(() => {
    const checkIfWalletConnected = async () => {
      try {
        const activeAccount = await beaconWallet.client.getActiveAccount();
        if (activeAccount) {
          setAddress(activeAccount.address);
          setIsConnected(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    checkIfWalletConnected();
  }, []);


  const connectWallet = async () => {
    try {
      await beaconWallet.requestPermissions();
      const address = await beaconWallet.getPKH();
      setAddress(address);
      setIsConnected(true);
    } catch (error) {
      console.log(error);
    }
  };

  const campaignId = '95314ed72ec34ed59a8688401170c529'; // Example campaign ID
  const amount = 1; // Example hardcoded amount

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://password-claim.objkt.party/api/request_mint/', {
        campaign_id: campaignId,
        password: password,
        claimer: address,
        amount: amount,
      });

      // If the backend returns a payload successfully, proceed with the Tezos transaction
      setPayload(response.data.payload);
      setError('');
      verifyPasswordAndSendTransaction(response.data.payload)
    } catch (error) {
      setPayload('')
      console.log(error)
      try{
          setError("Error:" + error.response.data.detail);
      }catch(e){
          setError("Error verifying password")
      }
    }
  };

  const verifyPasswordAndSendTransaction = async (passedPayload) => {
    try{
    const contract = await Tezos.wallet.at('KT1GHrkrZEzypxBQLWvKuj5QNTVdd1Xcndxu');
    const operation = await contract.methodsObject.claim(
      {
        amount: 1,
        burn_tokens: [],
        proxy_for: null,
        token_id: 2,
        condition_extra: passedPayload
      }
    ).send();

    await operation.confirmation();
    alert('Transaction successful!');
    } catch( error ) {
      console.error('Transaction error:', error);

    }
  };

  // Styles from the old file
  const appStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: 'black',
    color: '#FEE440',
    fontFamily: "'VT323', monospace",
    fontSize: '1em'
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  };

  const inputStyle = {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #FEE440',
    width: '200px',
    backgroundColor: 'black',
    color: '#FEE440',
    fontFamily: "'VT323', monospace",
    fontSize: '1.5em',
  };

  const buttonStyle = {
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#FEE440',
    color: 'black',
    cursor: 'pointer',
    fontFamily: "'VT323', monospace",
    fontSize: '1.5em'
  };

  const payloadStyle = {
    color: '#FEE440',
    width: '500px',
    wordBreak: 'break-all',
    fontSize: '1em'
  }

  const linkStyle ={
    color: '#FEE440',
    fontSize: '2em'
  }

  return (
    <div className="App" style={appStyle}>
      <p><a href='https://hackmd.io/@bPxktHCbRjOPEtpomPbKBA/HyrVIPuap' style={linkStyle}>What is this?</a></p>
      <a href='https://objkt.com/tokens/KT1GHrkrZEzypxBQLWvKuj5QNTVdd1Xcndxu/2' ><img src='IYKYK_THUMB.png' alt='nft to be claimed'></img></a><br/>
      {!isConnected ? (
        <button onClick={connectWallet} style={buttonStyle}>Connect Wallet</button>
        ) : (
        <form onSubmit={handleSubmit} style={formStyle}>
        <div>Wallet Address: {address}</div>
        <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter password"
        style={inputStyle}
        />
        <button type="submit" style={buttonStyle}>Verify and Claim</button>
        </form>
        )}
        {error && (
        <div>
        <h3>{error}</h3>
        </div>
        )}
        {payload && (
        <div>
        <h3>Transaction Payload:</h3>
        <p style={payloadStyle}>{payload}</p>
        </div>
        )}
        </div>
        );
        };
        
        export default App;